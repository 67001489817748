import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CaseStudyCard from '../components/case_study_card'

export const pageQuery = graphql`
    query CaseStudiesIndex {
        allContentYaml {
            edges {
                node {
                    title
                    description
                    path
                    image {
                        childImageSharp {
                            fluid(maxWidth: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`

const comparePath = (a, b) => {
  if (a.path > b.path) { return -1; }
  if (a.path < b.path) { return 1; }
  return 0;
}

const indexedCaseStudy = (caseStudy, i) =>
  <CaseStudyCard key={"case-study-" + i} caseStudy={caseStudy} />

const IndexPage = ({ data }) => {
  const caseStudies = data.allContentYaml.edges.map(edge => edge.node);
  const caseStudyCards = caseStudies.sort(comparePath).map(indexedCaseStudy);

  return (
    <Layout>
      <SEO title="Home" />
      <div className="my-12 sm:my-48 text-H3 sm:text-H1 font-bold font-serif">
        Hi! I'm Lynn — UX Designer, book nerd, and science enthusiast located in the SF Bay Area, CA.
      </div>
      {caseStudyCards}
    </Layout>
  )
}
export default IndexPage
